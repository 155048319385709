
.wrapper {
    height: 392px;
    width: 329px;
    background: #ffffff;
    border-radius: 8px;
    position: fixed;
    top: 16%;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    z-index: 55;
}
.title {
    font-family: 'mont';
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.btn {
    width: 50px;
    height: 32px;
    border-radius: 6px;
    border: none;
    background: #00893d;
    font-family: 'mont';
    color: #ffffff;
    font-size: 14px;
}
.btns {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px auto;
    gap: 5px;
}