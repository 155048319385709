

.link {
    display: flex;
    width: 120px;
    height: 36px;
    align-items: center;
    justify-content: center;


    border-radius: 8px;
    color:#262626;
    text-decoration: none;
    font-size: 12px;
    font: 'mont';
    background: #fff;
    border: 1px solid #3a3a3a;
}
.activeLink {
    border: none;
    color: #fff;
    background: #5d5ffe;
}
.category {
    display: flex;
    line-height: 10px;
}