
.wrapper {
    width: 340px;
    height: 197px;
    border: 1px solid red;
    border-radius: 6px;
    position: absolute;
    top: 97%;
    left: 19%;
    transform: translateX(-20%) translateY(-52%);
    z-index: 5;
    background: #ffffff;
    animation: error 0.6s ease ;
    padding: 43px;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 15px;
    
}
.text {
    font-family: 'mont';
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn {
    width: 50px;
    background: 0;
    border: none;
    color: red;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}
@keyframes error {
   25% {
    left: 17%;
   }
   50% {
    left: 19%;
   }
   75% {
    left: 17%;
   }
   100% {
    left: 19%;
   }
}