.alice-carousel__dots {
    margin:  5px 10px !important;
}

.alice-carousel__prev-btn {
   padding: 0 5px !important;
}
.alice-carousel__next-btn {
    padding: 0 5px !important;

}
