.header {
    background: rgba(68, 68, 68, 0.8);
    border-radius: 8px 8px 0 0;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #FFFFFF;
    gap: 15px;
}

.title {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #fff;
}

.plus:hover {
    border: 1px solid #fff;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 64px;
    gap: 24px;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 15;
    width: 453px;
    height: 793px;

    background: #F8F8F8;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
}

.item {
    display: flex;
    flex-direction: column;
}

.userAva {
    width: 30px;
    height: 30px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    display: flex !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex !important;
}

.date {
    width: 94px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.spec {
    display: flex;
    justify-content: space-between;
}

.date button {
    border: none;
    background: 0;
}

.priceItem {
    border: 1px solid;
    border-radius: 4px;
    padding: 10px;
}

.work {
   display: grid;
   grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 2fr;

    padding: 6px;
    gap: 5px;

    width: 100%;
    height: 64px;

    background: #F8F8F8;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    font-size: 11px;
    margin-top: 5px;
}

.item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}

.subHeader {
    display: grid;
    padding: 0px;
    gap: 5px;
    width: 100%;
    height: auto;
    background: #2D2D2D;
    border-radius: 0px 0px 8px 8px;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr 2fr;
}

.subHeaderItem {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 100%;
    font-size: 14px;
}

@media screen and (width < 768px){
    .header{
        height: 30px;
        justify-content: space-between;
        padding-right: 15px;
    }
    .title{
        font-size: 14px;
    }
    .subHeaderItem{
        font-size: 10px;
    }
    .item{
        font-size: 10px;
    }
    .subHeader{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .work{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0px;
        gap: 5px;
        border-radius: 0px 0px 8px 8px;
        justify-content: center;
    }
    .tableMobile{
        display: flex;
    }
    .tableMobile div:nth-child(2){
        width: 50%;
    }
    .item{
        align-self: center;
        flex-direction: row;
    }
    .works {
        display: none;
    }
   
}

