@font-face {
  font-family: "mont";

  src:
    url('./fonts/Mont-Regular.ttf')
}
@font-face {
  font-family: Inter;
  src: url(./fonts/Inter-Regular.ttf);
}





.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

input[type=file] {
  display: none;
}

.timeTable::-webkit-scrollbar {
  width: 0;
}

.btn {
  position: absolute;
  top: 0px;
  right: 20px;
  padding: 10px;
}

.btn button {
  margin: 5px;
  padding: 5px;
}





.content {

  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding: 20px 0;
}
.header {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
  
}



.css-13cymwt-control {
  height: 100% !important;
}

.css-13cymwt-control:hover {
  height: 100% !important;
}

.css-t3ipsp-control {
  height: 100%;
}

.css-1dimb5e-singleValue {
  overflow: visible !important;
  grid-area: inherit !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px;
}

.css-1dimb5e-singleValue {
  width: 115%;
  position: absolute;
  /* z-index: 85; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.css-1hb7zxy-IndicatorsContainer {
  width: 30px;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

@media (max-width: 767px) {
  .mainContainer {
    flex-direction: column;
    max-height: 809px;
  }

  .header {
    display: flex;
    flex-direction: row;
  }

  /* .content {
    overflow: hidden;
  } */

}

.calendar {
  width: 36px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.prev:hover .svg {
  background: #5D5FEF;
  fill: white;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar:hover {
  background: grey
}

.calendar:hover .svg {
  fill: white
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #FFFFFF;
  width: 24px;
  height: 24px;

}

.row:hover {
  animation: 1s rovHover;
  animation-fill-mode: forwards;
  border: 1px solid white;


}

@keyframes rovHover {
  from {
    background: black
  }

  to {
    background: #444444;
  }
}

.row:hover .prev {
  fill: #FFFFFF;
}

.row:hover .next {
  fill: #FFFFFF
}

.more {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border: 1px solid rgba(68, 68, 68, 0.2)
}

.more:hover {

  animation: 1.5s colorMore;
  animation-fill-mode: forwards
}

@keyframes colorMore {
  from {
    background: black;
  }

  to {
    background: rgba(68, 68, 68, 0.8);
  }
}

.more:hover .ellipse {
  fill: #FFFFFF;
  transition: fill 2s;
}

.css-1okebmr-indicatorSeparator {
  display: none;
  width: 0px;
  height: 0px;
  border: 1px solid red;
}

.css-tlfecz-indicatorContainer {
  padding: 0px;
  height: 10px;
  width: 15px;
  fill: #444444;
}

.css-1s2u09g-control {
  height: 100%;
  border: 2px solid black;
  display: flex;
  align-items: center;
}

.css-qc6sy-singleValue {
  width: 100%;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-319lph-ValueContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center
}

.css-6j8wv5-Input {
  overflow: hidden;
  height: 100%;
  border: none
}

.css-6j8wv5-Input:after {
  overflow: hidden;
  height: 100%;
  border: none
}


.react-calendar {
  width: 368px !important;
  padding: 20px;
  padding-left: -10px !important;
  height: 338px;
  border: none !important;
  border-radius: 8px !important;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex !important;
  margin-top: 10px !important;
  height: 44px;
  justify-content: center;
  padding: 0 20px;
}

.react-calendar__navigation button {
  background: none;
  width: 148px;
  margin-right: 49px !important;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none !important;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-family: 'Inter';
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.2em !important;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation button {
  opacity: 1;
}

.react-calendar__navigation__prev2-button {
  display: none !important
}

.react-calendar__navigation__next2-button {
  display: none
}

.react-calendar__navigation__label__labelText--from {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px
}

.react-calendar__month-view__days__day {
  font-size: 12px;
  font-family: 'mont';
  font-weight: 600
}

.react-calendar__month-view__days__day:hover {
  border: 1px solid #5D5FEF !important;
  background: #FFFFFF !important;
  border-radius: 8px;
  color: black;
}

.react-calendar__tile--active {
  background: #5D5FEF !important;
  border-radius: 8px;
}

.react-calendar__navigation__label__labelText .react-calendar__navigation__label__labelText--from {
  font-family: 'Inter' !important;
  font-weight: 600
}

.react-calendar__viewContainer {
  padding: 0 70px !important;
  margin-left: -45px
}

.css-i4bv87-MuiSvgIcon-root:hover {
  fill: #d10000 !important
}

.css-j204z7-MuiFormControlLabel-root {
  margin-right: -2px !important
}

.css-1xhj18k {
  flex-direction: column !important;
  align-items: center !important
}

.css-1pjrtiy-MuiPickersToolbar-root-MuiTimePickerToolbar-root {
  align-self: center !important
}

.css-1hbyad5-MuiTypography-root {
  position: absolute !important;
  top: 12px !important;
  left: 21px !important;
  display: none !important
}

.css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
  display: none
}

.root-MuiPickersToolbar-penIconButton {
  display: none
}

.MuiPickersToolbar-penIconButtonLandscape {
  display: none !important
}

.css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
  /*display: none !important*/

  flex-direction: column !important;
  gap: 10px;
  position: absolute;
  z-index: 50;
  border: 1px solid #DADCE0;
  width: 52px;
  height: 80px;
  left: 240px;
  top: 34px;

}

.css-1m5r8g7-MuiTimePickerToolbar-amPmSelection button {
  padding: 3.5px 0;

}

.css-1m5r8g7-MuiTimePickerToolbar-amPmSelection button:focus {
  background: #5D5FEF !important;
  border-radius: 0;

}

.css-1jm66jt-MuiClockPicker-root {
  margin-top: 24px !important
}

.css-1r3tc0d-MuiTimePickerToolbar-hourMinuteLabel {
  margin-top: 9px !important;
}

.css-1nql4ji-MuiTypography-root {
  background: 'rgba(33, 33, 33, 0.88)' !important;
  width: 96px !important;
  height: 80px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-weight: 600 !important;
  font-family: 'Roboto' !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.css-1nql4ji-MuiTypography-root::after {
  background-color: #d10000 !important;
}

.css-1nql4ji-MuiTypography-root:focus {
  color: #d10000 !important;
}

.css-1pjrtiy-MuiPickersToolbar-root-MuiTimePickerToolbar-root {
  max-width: 100% !important;
  margin-left: -61px !important
}

.css-1mp42oh-MuiTypography-root-MuiTimePickerToolbar-separator {
  font-size: 56px !important;
  color: black !important;
  font-family: 'Roboto' !important
}

.css-1x2wh54-MuiButtonBase-root-MuiButton-root {
  background: rgba(93, 95, 239, 0.2) !important;


}

.css-1x2wh54-MuiButtonBase-root-MuiButton-root:hover {
  color: blue;
}

.css-1r3tc0d-MuiTimePickerToolbar-hourMinuteLabel {
  align-items: center !important
}

.css-hlj6pa-MuiDialogActions-root {
  display: none !important
}

.css-1x2wh54-MuiButtonBase-root-MuiButton-root:nth-child(2) {
  background: none !important;
  font-family: 'mont' !important
}

.css-1x2wh54-MuiButtonBase-root-MuiButton-root:nth-child(3) {

  font-family: 'mont' !important
}

.css-1m5r8g7-MuiTimePickerToolbar-amPmSelection button {
  background: none !important
}

.css-j3g793-MuiCalendarOrClockPicker-root {
  flex-direction: column !important;
}

.react-tel-input .form-control {
  /*height: 50px !important;*/
  width: 100% !important;
  border-radius: 8px !important;
  border: 1px solid rgba(228, 228, 228, 0.9) !important;
  font-family: 'Mont'
}
#clientList::-webkit-scrollbar {
    width: 5px
}
#clientList::-webkit-scrollbar-thumb {
  background-color: #cacaca;
}

.BTN {
  color: red;
  opacity: 0;
  background: #006edc;
}
.BTN:hover{
  opacity: 1;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto:wght@100&display=swap');

