
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
}
.graphics{
    max-height: 536px;
    display: flex;
    margin-top: 34px;
}


.search{
    display: flex;
    align-items: center;
}
.header {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.graphicsContainer {
    height: 100%;
    display: flex;
}
.graphic{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 50%;
}
@media screen and (max-width: 768px) {
    .graphics {
        flex-direction: column;
        overflow-y: auto;
    }
    .graphic {
        width: 100% !important;
    }
    .graphicsContainer {
        width: 100%
    }
};