.close {
    height: 12px;
    width: 12px;
    align-self: end;
    background-color: #fa625c;
    border-radius: 50%;
    border-color: transparent;
    transition: all .2s;
}
.close:hover{
    height: 12px;
    width: 12px;
    cursor: pointer;
    transition: all .2s;
    background-image: url(https://help.apple.com/assets/65A8106E7C69B635140E606E/65A81072C0272B1FFA02DE51/ru_RU/65bd6ceba8d6ebaaf76e8f8686bab20a.png);
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-position: center center;
}

.folding{
    height: 12px;
    width: 12px;
    align-self: end;
    background-color: #fbb43a;
    border-radius: 50%;
    border-color: transparent;
}
.folding:hover{
    height: 12px;
    width: 12px;
    cursor: pointer;
    transition: all .2s;
    background-image: url(https://help.apple.com/assets/65A8106E7C69B635140E606E/65A81072C0272B1FFA02DE51/ru_RU/05795a349c462cd1feaf13bcfe02cf2c.png);
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-position: center center;
}

.unfolding{
    height: 12px;
    width: 12px;
    align-self: end;
    background-color: #3ec544;
    border-radius: 50%;
    border-color: transparent;
}

.unfolding:hover{
    height: 12px;
    width: 12px;
    cursor: pointer;
    transition: all .2s;
    background-image: url(https://help.apple.com/assets/65A8106E7C69B635140E606E/65A81072C0272B1FFA02DE51/ru_RU/ffa49e33e12951b1f859a06b9f566fab.png);
    background-size: 100% 100%;
    background-repeat:no-repeat;
    background-position: center center;
}