
.link{
    display: block;
    color: #000;

}
.link:hover {
    border-bottom: 2px solid #5d5ffe;
    padding-right: 0;
    padding-top: 2px;
}
.linkActive {
    display: block;
    color: #5d5ffe;
    border-bottom: 2px solid #5d5ffe;
}