


.notifBox{
    display: flex;
    position: relative;

}

.notifBox:hover {
    background: rgb(217, 217, 217) !important;
    
}

.settingWindow {
    position: absolute;
    z-index: 38;
    right: 45px;
    top: -5px;
    border-radius: 4px;
    padding: 5px 0;
    width: 90%;
    background: #fff;
    box-shadow:  rgba(0, 0, 0, 0.1) 0px 4px 32px 0px;
    
}
.settingWindowBtn {
    
    padding: 10px;
}
.settingWindowBtn:hover {
    background: #5d5ffe;
    cursor: pointer !important;
    border-radius: 0%;
    color: #fff;
}